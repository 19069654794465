<template>
  <v-card style="height: calc(100vh - 38px)">
    <v-card-title>
      <v-card width="100%" class="pa-0" elevation="5">
        <v-layout row justify-start align-center>
          <div class="pr-4">
            <v-btn
              icon
              @click="$router.push({ name: 'purchaseOrders' })"
            >
              <v-icon
                color="primary"
              >
                fas fa-arrow-alt-left
              </v-icon>
            </v-btn>
          </div>
          <h3>
            Receive PT {{ $route.params.id }}
          </h3>
          <v-spacer/>
        </v-layout>
      </v-card>
      <v-progress-linear v-if="!fetched" class="pt-0 mt-0 mx-1" indeterminate color="info" style="border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;"/>
    </v-card-title>
    <v-fade-transition>
      <v-card-text v-if="fetched" style="height: calc(100vh - 202px)">
        <v-container py-0 fluid style="height: 100%;">
          <v-layout column justify-start style="height: 100%;">
            <v-flex shrink>
              <v-layout align-center fill-height>
                <v-container grid-list-xl pt-0>
                  <v-form ref="values">
                    <v-layout row nowrap justify-start align-center>
                      <v-flex xs5 md4 lg2>
                        <v-text-field
                          v-model="totalValue"
                          v-currency
                          outline
                          readonly
                          hide-details
                          label="Total value to receive"
                          color="blue darken-3"
                        />
                      </v-flex>
                      <v-flex xs5 md4 lg3 shrink>
                        <v-text-field
                          v-model="valueToReassign"
                          outline
                          readonly
                          hide-details
                          persistent-hint
                          label="Value needing assignment"
                          :rules="[ e => Number(e) === 0 || 'This must be zero' ]"
                        />
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-container>
              </v-layout>
            </v-flex>
            <v-flex style="height: 90%">
              <ag-grid-vue
                class="ag-grid-traqsys"
                style="height: 100%;"
                :gridOptions="gridOptions"
                :rowData="items"
                :frameworkComponents="frameworkComponents"
                @cell-key-down="gridKbEventSender"
              ></ag-grid-vue>
            </v-flex>
            <v-slide-y-reverse-transition>
            <v-flex shrink pt-4 v-if="alertMessage || hasDuplicateSerials">
              <v-layout justify-center>
                <v-scale-transition group mode="in-out" leave-absolute>
                  <v-alert key="error" v-if="hasDuplicateSerials" :value="true" type="error">You have two items with the same serial number</v-alert>
                  <v-alert key="info" v-else :value="alertMessage" :type="alertType">{{ alertMessage }}</v-alert>
                </v-scale-transition>
              </v-layout>
            </v-flex>
            </v-slide-y-reverse-transition>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-fade-transition>
    <v-fade-transition>
      <v-card-actions style="background-color: #dfdfdf; width: 100%" v-if="fetched">
        <v-layout row nowrap justify-start align-center>
          <v-flex xs4 shrink>
            <printers :disabled="succeeded" :printer="printer" @updated="printer = $event.value"/>
          </v-flex>
          <v-flex shrink pl-4>
            <v-checkbox
              v-model="printTags"
              color="purple accent-4"
              label="Print Received Labels"
              :disabled="succeeded"
            />
          </v-flex>
          <v-spacer></v-spacer>
          <cancel-button class="mr-2" :success="succeeded" @click="cancel"/>
          <submit-button :loading="saving" :disabled="saving || succeeded || hasDuplicateSerials || !hasEdits" @click="submit"/>
        </v-layout>
    </v-card-actions>
    </v-fade-transition>
  </v-card>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import columns from '@/components/mixins/columns'
import {
  PURCHASED_ITEMS_TO_RECEIVE,
  DELETE_PURCHASED_ITEM,
  UPDATE_PURCHASED_ITEMS, GET_PURCHASED_ITEMS, UPDATE_PURCHASE_ORDER_STATUS, GET_PURCHASE_ITEMS_RECEIVE_STATUS
} from '@/api/graphql/Constants/Orders'
import partNumberCell from '@/components/renderers/partNumberCell'
import printers from '@/components/autocompletes/printers'
import checkbox from '@/components/renderers/checkbox'
import locationCellEditor from '@/components/renderers/locationCellEditor'
import { GET_COUNTRIES } from '@/api/graphql/Constants/Countries'
import { ITEMS, UPDATE_INVENTORY_ITEM } from '@/api/graphql/Constants/Inventory'
import countryOfOriginCellEditor from '@/components/renderers/countryOfOriginCellEditor'
import { CREATE_PRINT_JOB, prepInventoryLabelsForPrintJob } from '@/api/graphql/Constants/printNode'
import { COLUMN_TYPES, COLUMNS__PURCHASES_RECEIVING } from '@/lib/agGridColumnConfiguration'
import { CONTEXT_MENU__RECEIVING } from '@/lib/agGridContextMenuConfigurations'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { KB_SHORTCUT__RECEIVING } from '@/lib/agGridKbShortCuts'
import { BREAKDOWN_ITEM_STATUS, BREAKDOWN_STATUS } from '@/models/ExtraBackendModels'
import {
  GET_COUNT_OF_UNFINISHED_BREAK_DOWN_ITEMS_ON_BREAK_DOWN,
  UPDATE_BREAK_DOWN_ITEM_STATUS, UPDATE_BREAK_DOWN_STATUS
} from '@/api/graphql/Constants/Disassembly'
import { freshDeskWidget } from '@/lib/freshDesk.ts'
import SubmitButton from '@/components/buttons/SubmitButton'
import CancelButton from '@/components/buttons/CancelButton'
import { GetTimeZonedDateTime } from '@/lib/Dates'
import { GetDjangoMoneyCompatibleInput } from '@/lib/moneyHelpers'
import { c } from '@/lib/Currency.ts'
export default {
  name: 'receiving',
  mixins: [columns],
  components: {
    AgGridVue,
    'printers': printers,
    'submit-button': SubmitButton,
    'cancel-button': CancelButton
  },
  computed: {
    columnConfig () {
      return this.$store.getters['profile/tableConfig']('receiving')
    },

    columnConfigId () {
      return this.$store.getters['profile/tableConfigId']('receiving')
    },

    user () {
      return this.$store.state.profile.user
    },

    correctPrintSettings () {
      return (this.printer && this.printTags) || (!this.printer && !this.printTags)
    },

    canSubmit () {
      return this.correctPrintSettings && (this.itemsToReceive.length > 0 || this.itemsToDelete.length > 0)
    },

    valueToReassign () {
      let value = 0
      for (const item of this.items) {
        value += Number(item.original_cost.amount)
      }
      return (c(this.totalValue).value - value).toFixed(2)
    },

    itemsToReceive () {
      return this.items.filter(i => i.item.receive_status).map(i => this.getItemsToReceive(i))
    },

    itemsToDelete () {
      return this.items.filter(i => i.delete)
    },

    purchaseItemsToEditCost () {
      try {
        return this.items.filter(i => i.item.receive_status && i.watch.original_cost.isEdited).map(i => ({
          id: i.id,
          original_cost: GetDjangoMoneyCompatibleInput(i.original_cost.amount)
        }))
      } catch (error) {
        return []
      }
    },

    tagsToPrint () {
      return this.items.filter(i => i.item.receive_status).map(i => ({
        pn: i.item.part.pn,
        sn: i.item.serial_number,
        location: i.item.location.name,
        id: i.item.id,
        pt: `${i.transaction.id}-${i.line_number}`
      }))
    },

    itemsToMaybeEditForBreakdown () {
      return this.items.filter(i => i.item.receive_status && (i.item.broke_down_items?.id ?? 0))
    },

    hasEdits () {
      return this.items.some(i => {
        for (const key in i.watch) {
          if (i.watch[key].isEdited) return true
        }
      })
    },

    hasDuplicateSerials () {
      const receivedItems = this.items.filter(i => {
        for (const key in i.watch) {
          if (i.watch[key].isEdited) return true
        }
        return false
      }).map(i => ({ id: i.item.id, pn: i.item.part.id, sn: i.item.serial_number }))
      for (const item of receivedItems) {
        if (item.sn) {
          const itemWithDuplicateSn = receivedItems.find(i => i.id !== item.id && item.sn === i.sn)
          if (itemWithDuplicateSn !== undefined) return true
        }
      }
      return false
    }
  },
  watch: {
    columnConfig: function (value) {
      if (Object.prototype.toString.call(value) === '[object Array]' && this.columnsNotSet) {
        this.columnApi.setColumnState(value)
      }
    }
  },
  data () {
    return {
      alertType: 'info',
      alertMessage: '',

      printer: 0,
      printTags: false,
      succeeded: false,
      saving: false,
      fetched: false,
      loading: true,
      items: [],
      originalItems: [],
      countries: [],
      columnsNotSet: false,

      totalValue: 0,

      /* Grid Variables */
      frameworkComponents: null,
      // grid setup
      gridOptions: null,
      // end gridOptions
      editableNodes: [],
      columns: COLUMNS__PURCHASES_RECEIVING,
      contextMenuItems: params => CONTEXT_MENU__RECEIVING(params),
      gridApi: null,
      columnApi: null,

      /* end Grid Variables */

      order: 0,
      routeFilters: [],
      showReceived: false,
      syncedReceivedStatus: false
    }
  },
  apollo: {
    items: {
      query: PURCHASED_ITEMS_TO_RECEIVE,
      variables () {
        const receiveFilter = [{ key: 'transaction__id', value: this.$route.params.id }, { key: 'item__receive_status', value: this.showReceived }]
        this.showReceived && receiveFilter.pop()
        receiveFilter.push(...this.routeFilters)
        return {
          input: {
            filters: receiveFilter
          }
        }
      },
      update (data) {
        this.totalValue = 0
        this.fetched = true
        const items = data.items.map(i => {
          i.delete = false
          i.watch = {
            serial_number: { value: i.item.serial_number ?? '', isEdited: false },
            location: { value: i.item.location?.id ?? 0, isEdited: false },
            country_of_origin: { value: i.item.country_of_origin?.code ?? false, isEdited: false },
            receive_status: { value: i.item.receive_status, isEdited: false },
            delete: { value: false, isEdited: false },
            original_cost: { value: i.original_cost, isEdited: false }
          }
          this.totalValue += Number(i.original_cost.amount)
          return i
        })
        this.originalItems = JSON.parse(JSON.stringify(items))
        return items
      },
      fetchPolicy: 'no-cache',
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    },
    countries: {
      query: GET_COUNTRIES
    }
  },
  methods: {
    getItemsToReceive (ptItem) {
      const item = ptItem.item
      return {
        id: item.id,
        ...(item.location?.id && { location_id: item.location.id }),
        ...(item.country_of_origin && { country_of_origin: item.country_of_origin.code }),
        ...(item.serial_number && { serial_number: item.serial_number }),
        received_by_id: this.user.id,
        receive_status: item.receive_status,
        received_date: GetTimeZonedDateTime()
      }
    },

    gridKbEventSender (params) {
      if (['INPUT', 'TEXTAREA'].indexOf(document.activeElement.nodeName) === -1) GridKbEventHandler(params, [], KB_SHORTCUT__RECEIVING(params))
    },

    reload () {
      this.$apollo.queries.items.refetch()
      this.$apollo.query({
        query: ITEMS,
        variables: {
          input: {
            filters: [
              {
                key: 'purchases_items_details__transaction__id',
                value: this.$route.params.id
              }
            ]
          }
        }
      })
    },

    async cancel () {
      await this.$router.push({ name: 'purchaseOrders' })
    },

    resultHandler ({ message, type }) {
      this.alertMessage = message
      this.alertType = type
      this.saving = false
      if (type === 'success') {
        this.succeeded = true
      }
    },

    async syncReceiveStatus () {
      try {
        const query = {
          query: GET_PURCHASE_ITEMS_RECEIVE_STATUS,
          variables: { id: this.$route.params.id }
        }
        const response = await this.$apollo.query(query)
        if (response.data.items) {
          const receivedCount = []
          let status
          for (const item of response.data.items) {
            item.receive_status && receivedCount.push(item)
          }
          if (receivedCount.length === 0) {
            status = 4
          } else if (receivedCount.length === response.data.items.length) {
            status = 6
          } else status = 5

          const mutation = {
            mutation: UPDATE_PURCHASE_ORDER_STATUS,
            variables: {
              id: this.$route.params.id,
              status: status
            }
          }
          await this.$apollo.mutate(mutation)
          this.syncedReceiveStatus = true
        }
      } catch (error) {
        console.log(error.message)
      } finally {
      }
    },

    async submit () {
      if (this.$refs.values.validate() && this.canSubmit) {
        this.saving = true
        let message = 'Updated items successfully!'
        try {
          if (this.itemsToDelete.length > 0) { // if there are items to delete, delete them
            await this.deleteItems(this.itemsToDelete)
          }
          if (this.itemsToReceive.length > 0) { // if there are items to receive, receive them
            await this.receiveItems(this.itemsToReceive)
          }
          if (this.purchaseItemsToEditCost.length > 0) { // if costs were changed on items
            await this.updatePurchasedItemsCost(this.purchaseItemsToEditCost)
          }
          if (this.printTags) { // if we want to print tags (and there are items being received)
            await this.printItemLabels(this.tagsToPrint)
          }
          if (this.itemsToMaybeEditForBreakdown.length > 0) { // if we want to process items that are on a breakdown (and they are being received)
            message = await this.processBreakdownItems(this.itemsToMaybeEditForBreakdown)
          }
          await this.syncReceiveStatus()
          this.resultHandler({ message: message, type: 'success' })
        } catch (error) {
          const message = error.message ? error.message : error
          this.resultHandler({ message: message, type: 'error' })
        }
      } else if (!this.correctPrintSettings) {
        this.resultHandler({ message: 'Invalid print configuration.', type: 'warning' })
      } else if (!this.$refs.values.validate()) {
        this.resultHandler({ message: 'Money is not aligned.', type: 'warning' })
      } else {
        this.resultHandler({ message: 'To change data, mark an item for deletion or receiving.', type: 'warning' })
      }
    },

    deleteItems (items) {
      return new Promise((resolve, reject) => {
        const promises = []
        for (const ptItem of items) {
          promises.push(this.$apollo.mutate({
            mutation: DELETE_PURCHASED_ITEM,
            variables: { id: ptItem.id }
          }).catch(error => {
            const message = error.message ? error.message : error
            reject(message)
          }))
        }
        Promise.all(promises).then(() => resolve())
      })
    },

    async updatePurchasedItemsCost (items) {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_PURCHASED_ITEMS,
          variables: { input: items }
        })
      } catch (error) {
        const message = error.message ? error.message : error
        throw new Error(message)
      }
    },

    async receiveItems (items) {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_INVENTORY_ITEM,
          variables: { input: items }
        })
      } catch (error) {
        const message = error.message ? error.message : error
        throw new Error(message)
      }
    },

    async printItemLabels (items) {
      try {
        const zpl = prepInventoryLabelsForPrintJob(items)
        await this.$apollo.mutate({
          mutation: CREATE_PRINT_JOB,
          variables: {
            content: zpl,
            contentType: 'raw_base64',
            printerId: this.printer,
            qty: 1,
            source: `${this.user.firstName} ${this.user.lastName}`,
            title: `Inventory Labels for PT: ${this.$route.params.id}`
          }
        })
      } catch {
        throw new Error('Could not print tags, check printer and try again.')
      }
    },

    async processBreakdownItems (items) {
      const breakdownItemsToProcess = []
      const breakdownId = items[0].item.broke_down_items.break_down.id
      for (const ptItem of items) {
        breakdownItemsToProcess.push({
          id: ptItem.item.broke_down_items.id,
          status_id: BREAKDOWN_ITEM_STATUS.BROKEN_OUT
        })
      }
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_BREAK_DOWN_ITEM_STATUS,
          variables: { input: breakdownItemsToProcess }
        })
        await this.checkBreakdownForStatusChanges(breakdownId)
        return 'Successfully received items and updated their break down status'
      } catch {
        throw new Error('Received items but could not update their break down status.')
      }
    },

    async checkBreakdownForStatusChanges (id) {
      try {
        const response = await this.$apollo.query({
          query: GET_COUNT_OF_UNFINISHED_BREAK_DOWN_ITEMS_ON_BREAK_DOWN,
          variables: { id: id }
        })
        const itemsLeft = response.data.breakdown_break_down_items
        if (itemsLeft.length === 0) {
          await this.$apollo.mutate({
            mutation: UPDATE_BREAK_DOWN_STATUS,
            variables: { id: id, status: BREAKDOWN_STATUS.BROKEN_DOWN }
          })
        }
      } catch {
        throw new Error('Could not check for broke down item statuses.')
      }
    }
  },
  created () {
    // check route for params
    const params = this.$route.query
    if (params.filters) {
      this.routeFilters = [...params.filters]
    }
  },
  beforeDestroy () {
    freshDeskWidget?.unBump()
  },
  mounted () {
    freshDeskWidget?.bump()
    this.gridOptions = {
      allowDragFromColumnsToolPanel: true,
      animateRows: true,
      // cacheBlockSize: 50,
      // cacheOverflowSize: 30,
      // maxConcurrentDatasourceRequests: 2,
      columnDefs: this.columnize(this.columns),
      columnTypes: COLUMN_TYPES,
      defaultColDef: {
        cellClass: (params) => {
          let search
          if (params.data === undefined) {
            search = params.node.id
          } else {
            search = params.data[Object.keys(params.data)[0]] + ' ' + params.colDef.field
          }
          if (this.editableNodes.indexOf(search) > -1) {
            return 'editable pt-1'
          }
          return 'pt-1'
        },
        editable: (params) => {
          const search = params.data[Object.keys(params.data)[0]] + ' ' + params.colDef.field
          if (this.editableNodes.indexOf((search)) > -1) {
            return 'editable'
          }
        },
        enableValue: false,
        enableRowGroup: false,
        enablePivot: false,
        menuTabs: ['generalMenuTab'],
        sortable: true,
        resizable: true
      },
      enterMovesDownAfterEdit: true,
      editType: 'singleCell',
      getContextMenuItems: this.contextMenuItems,
      getRowNodeId: (data) => {
        return data.id
      },
      headerHeight: 50,
      onDragStopped: (params) => {
        const columnState = params.columnApi.getColumnState()
        this.$store.dispatch('profile/updateTableConfig', { table: 'receiving', config: columnState })
      },
      onColumnResized: (params) => {
        if (params.finished) {
          const columnState = params.columnApi.getColumnState()
          this.$store.dispatch('profile/updateTableConfig', { table: 'receiving', config: columnState })
        }
      },
      onGridReady: (params) => {
        this.gridApi = params.api
        this.columnApi = params.columnApi
        this.$emit('ready', params)
        if (this.columnConfig.length !== 0) {
          params.columnApi.setColumnState(this.columnConfig)
        } else {
          this.columnsNotSet = true
        }
        const columnState = params.columnApi.getColumnState()
        const allHidden = columnState.every(c => c.hide === true)
        if (allHidden) {
          columnState.forEach(c => { c.hide = false })
          params.columnApi.setColumnState(columnState)
          this.columnConfigId && this.$store.dispatch('profile/deleteTableConfig', { index: this.columnConfigId })
        }
        const sort = [{ colId: 'lineNumber', sort: 'asc' }]
        params.api.setSortModel(sort)
        // params.api.setServerSideDatasource(this.ServerSideDataSource(this.gridOptions, this))
      },
      rowDeselection: true,
      rowHeight: 40,
      rowModelType: 'clientSide',
      rowSelection: 'multiple',
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true
            }
          }
        ]
      },
      singleClickEdit: true,
      sortingOrder: ['asc', 'desc'],
      stopEditingWhenGridLosesFocus: true,
      suppressMultiRangeSelection: true,
      suppressPropertyNamesCheck: true,
      tooltipShowDelay: 0
    }
    this.frameworkComponents = {
      partNumberCell: partNumberCell,
      checkbox: checkbox,
      locationCellEditor: locationCellEditor,
      countryCellEditor: countryOfOriginCellEditor
    }
  }
}
</script>
